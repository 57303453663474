import React, { useEffect, useRef, useState } from "react";
import styles from "./graph-ai-chat-body.module.scss";
import clsx from "clsx";
import { CHAT_LOADER_COLOR } from "components/charts/colors";
import { ReactComponent as ChatLogo } from "images/chat-logo.svg";
import { ChatBubble } from "components/graph-ai/chat-bubble/chat-bubble";


export interface ChatBotMessage {
  message: string;
  isUser: boolean;
  isNewMessage: boolean;
  id: string | undefined;
  isDefaultMsg: boolean;
  isLiked?: boolean;
  isDisliked?: boolean;
}

type Props = {
  isExpanded: boolean,
  onChangeMultiline: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSendClick: () => void;
  isLoading: boolean;
  messages: ChatBotMessage[]
  turnProcess: []
};


const ChatAiBody = ({
  isExpanded,
  isLoading,
  onSendClick,
  onChangeMultiline,
  messages,
  turnProcess
}: Props) => {
  const chatDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    chatDivRef.current?.scrollIntoView({ behavior: 'smooth' });
  });

  return (
    <div className={clsx(isExpanded ? [styles.chatBodyContainerExpanded] : [styles.chatBodyContainer])}>
      {/* Mapping messages from the API */}
      <div
        className={clsx(
          !isExpanded ? [styles.chatBody] : [styles?.chatExpandBody]
        )}
      >
        {messages.map((message: any, index) => (
          <div
            className={clsx(
              styles.chatBubble,
              message?.isUser && styles.chatBubbleUser
            )}
            key={index}
            ref={chatDivRef}
          >
            {!message?.isUser && <ChatLogo className={styles.systemImage} />}
            <div
              className={clsx(message?.isUser && styles.chatBubbleUserText)}
            >
              <div className={styles.subprocessWrapper} style={{ marginBottom: !message?.isUser ? "10px" : 0 }}>
                {!message?.isUser && message?.turnSubprocesses?.map((turn: any) => {
                  return (
                    <div>
                      {`
                      ${turn?.icon} ${turn?.heading?.value}`}
                    </div>
                  )
                })}
              </div>
              {/* Show three dots loader if state is loading, else print the message */}
              {isLoading && index === messages.length - 1 ? (
                <div
                  className={styles.loader}
                  style={
                    { "--loader-color": CHAT_LOADER_COLOR } as React.CSSProperties
                  }
                ></div>
              ) : (
                <>
                  {/* <div
                    className={clsx(message?.isUser && styles.chatBubbleUserText)}
                  >
                    {message?.message}
                  </div> */}
                  <ChatBubble
                    message={message?.message}
                    isUser={message?.isUser}
                    isExpand={isExpanded}
                    id={message?.id}
                  />
                </>
              )}
            </div>
          </div>
        ))}

      </div>

    </div>
  );
};

export default ChatAiBody;
