import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./graph-ai-body.module.scss";
import ChatHistory from "./chat-history";
import GraphChart from "./graph-chart";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  getAthleteList,
  getCurrentAthlete,
  getSeletedIds,
  setSelectedIds,
} from "store/slices/shared";
import {
  getAiGraphChatHistory,
  getAiGraphChatQuery,
  getAiGraphConfiguration,
} from "api/ai-graph";
import { countryCodesMapping } from "country-codes-mapping";
import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from "const";
import { ReactComponent as DropDown } from "images/dropdown.svg";
import { AthletePopup } from "components/athlete-popup/athlete-popup";
import { fetchAiGraphId } from "store/slices/aiGraph";
import ChatAiBody from "./graph-ai-chat-body";
import GraphAiChatInput from "./graph-ai-chat-input";

export interface ChatBotMessage {
  message: string;
  isUser: boolean;
  isNewMessage: boolean;
  id: string | undefined;
  isDefaultMsg: boolean;
  isLiked?: boolean;
  isDisliked?: boolean;
  turnSubprocesses: []
}

const GraphAiBody = () => {
  const dispatch = useDispatch();
  const currentAthlete = useSelector(getCurrentAthlete);
  const selectedIds = useSelector(getSeletedIds);
  const athleteList = useSelector(getAthleteList);
  const graphId = useSelector((state: any) => state?.aiGraph?.aiGraphId);
  const [isGraphExpanded, setIsGraphExpanded] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [userMessage, setUserMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [filteredAthlete, setFilteredAthlete] = useState<any>(athleteList);
  const [togglePopup, setTogglePopup] = useState(false);
  const [chatBotMessages, setChatBotMessages] = useState<ChatBotMessage[]>([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false)
  const [graphHistory, setGraphHistory] = useState<[]>([])
  const [isNewChat, setIsNewChat] = useState(false)
  const [isHistoryApi, setIsHistoryApi] = useState(false)
  const [historyLoading, setHistoryLoading] = useState(false)
  const [turnProcess, setTurnProcess] = useState<[]>([])
  const [activateGraph, setActivateGraph] = useState(false);

  const countryAlpha2Code =
    currentAthlete?.countryCode &&
      countryCodesMapping[currentAthlete.countryCode]
      ? countryCodesMapping[currentAthlete?.countryCode]
      : "AE";
  const flagUrl = `${DEFAULT_CDN_URL}${countryAlpha2Code.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;

  const handleExpandClick = () => {
    setIsGraphExpanded(!isGraphExpanded);
  };

  const handleInputChangeMultiline = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setUserMessage(e.target.value);
  };

  // default chat-bot message 
  useEffect(() => {
    setChatBotMessages(
      [{
        message: "Hello, How can I help you?",
        isUser: false,
        isNewMessage: false,
        id: "",
        isDefaultMsg: true,
        turnSubprocesses: []
      },]
    )
  }, [isNewChat])


  //ask api call
  useEffect(() => {
    if (graphId && isButtonClicked) {
      setIsButtonClicked(false)
      const handleChunkReceive = (chunk: string) => {
        const lines = chunk.split("\n");

        lines.forEach((line) => {
          if (line.startsWith("data: ")) {
            const jsonData = line.substring(6);

            try {
              const parsedChunk = JSON.parse(jsonData);
              const id = parsedChunk.id;

              const message = parsedChunk.message || null;
              const turnSubprocesses = parsedChunk.turn_subprocesses;
              const isUser = parsedChunk.actor !== "assistant";
              setIsLoading(!message && true);
              const newMessage = {
                id,
                message,
                isUser,
                turnSubprocesses,
                isNewMessage: true,
                isDefaultMsg: false,
              };
              setTurnProcess(turnSubprocesses)

              setChatBotMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];
                updatedMessages[updatedMessages?.length - 1] = newMessage;
                return updatedMessages
              });

              // setChatBotMessages((prevMessages) => {
              //   const lastMessage = prevMessages[prevMessages.length - 1];
              //   if (
              //     lastMessage?.message?.length < newMessage?.message?.length
              //   ) {
              //     return [
              //       ...prevMessages,
              //       // ...prevMessages.slice(0, prevMessages.length - 1),
              //       newMessage,
              //     ];
              //   }
              //   return prevMessages;
              // });
            } catch (err) {
              console.error("Failed to parse JSON:", err);
            }
          }
        });
      };

      (async () => {
        const resp = await getAiGraphChatQuery(
          graphId?.id,
          userMessage,
          handleChunkReceive
        );
        const res = await getAiGraphConfiguration(graphId?.id)
        if (chatBotMessages?.length == 3) {
          setHistoryLoading(true)
          setIsHistoryApi(!isHistoryApi)
        }
        setUserMessage("");


      })();
    }
  }, [graphId, userMessage, isButtonClicked, chatBotMessages, isHistoryApi]);



  const handleSendClick = async (): Promise<void> => {
    if (!currentAthlete?.id || userMessage.trim() === "") return;

    if (chatBotMessages?.length <= 1) {
      dispatch(fetchAiGraphId("2024-06-11", "2024-09-11"));
    }

    setIsButtonClicked(true);
    setIsLoading(true);
    setChatBotMessages((prevMessages) => [
      ...prevMessages,
      {
        message: userMessage,
        isUser: true,
        isNewMessage: true,
        id: "24",
        isDefaultMsg: false,
        turnSubprocesses: []
      },
      {
        message: "",
        isUser: false,
        isNewMessage: false,
        id: "24",
        isDefaultMsg: false,
        turnSubprocesses: []
      },
    ]);
  };

  const newApiRes = useMemo(
    () => ({
      id: "graph_001",
      state: "active",
      schema_version: 1,
      ai_graph_type_metadata: {
        ai_graph_type: "custom_graph",
      },
      cached_content: {
        thumbnail: {
          id: "thumb_001",
          data: "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAgIBAM8pdXkAAAAASUVORK5CYII=",
        },
      },
      ai_graph_stats: {
        usage_count: 10,
        used_as_creation_source_count: 5,
      },
      ai_graph_user_metadata: {
        creation_user: {
          user_id: 1,
        },
        current_user: {
          user_id: 2,
          graph_attributes: {
            folders: ["Folder1", "Folder2"],
            tags: ["Tag1", "Tag2"],
          },
          pinned_information: {
            tab_name: "Tab1",
            tab_group_level: 1,
            tab_sequence_number: 1,
          },
        },
        shares: [
          {
            share_link: "http://share_link",
            share_status: "Active",
            shared_by_user_id: 1,
            shared_to_user_id: 2,
            is_link_active: true,
            is_share_link_public: false,
            share_message: "Here is the graph data",
          },
        ],
      },
      conversation_turns: {
        turns: [
          {
            id: "turn_001",
            sequence_number: 1,
            actor: "user",
            actor_user_id: 1,
            message:
              "Hey, can you add Vo2 max of this athlete to graph and provide the max value?",
            generated_diff_message: null,
            turn_subprocesses: [],
            ai_graph_snapshot_data: {
              id: "snapshot_001",
              graph_id: "graph_001",
              data_hash: "5d41402abc4b2a76b9719d911017c592",
              ai_graph_in_scope: null,
              created_at: "2024-09-09T08:12:48.529682",
            },
            created_at: "2024-09-09T08:12:48.529689",
          },
          {
            id: "turn_002",
            sequence_number: 2,
            actor: "assistant",
            actor_user_id: 1,
            message: "Initial message",
            generated_diff_message: null,
            turn_subprocesses: [
              {
                id: "subprocess_001",
                sequence_number: 1,
                heading: {
                  value: "Fetching data from Data engine",
                  is_ai_generated: false,
                },
                message: "Fetching data from Data engine",
                created_at: "2024-09-09T08:12:48.529702",
                icon: "✔",
                is_inside_execution_scope: true,
              },
              {
                id: "subprocess_002",
                sequence_number: 2,
                heading: {
                  value: "Updating the graph",
                  is_ai_generated: false,
                },
                message: "Updating the graph",
                created_at: "2024-09-09T08:12:48.529706",
                icon: "✔",
                is_inside_execution_scope: true,
              },
            ],
            ai_graph_snapshot_data: {
              id: "snapshot_001",
              graph_id: "graph_001",
              data_hash: "5d41402abc4b2a76b9719d911017c592",
              ai_graph_in_scope: null,
              created_at: "2024-09-09T08:12:48.529708",
            },
            created_at: "2024-09-09T08:12:48.529709",
          },
        ],
        page_no: 1,
        limit: 20,
      },
      ai_graph_in_scope: {
        graph_type: "athlete_level",
        data_sources: [
          {
            id: "athlete_2227826",
            graph_data_source_type: "athlete_data_source",
            source: {
              type: "AthleteDataSourceMetadata",
            },
            is_mutable: true,
            created_at: "2024-09-09T08:12:48.529723",
          },
          {
            id: "graph_start_date",
            graph_data_source_type: "graph_start_date",
            source: {
              type: "GraphDateSourceMetadata",
            },
            is_mutable: true,
            created_at: "2024-09-09T08:12:48.529760",
          },
          {
            id: "graph_end_date",
            graph_data_source_type: "graph_end_date",
            source: {
              type: "GraphDateSourceMetadata",
            },
            is_mutable: true,
            created_at: "2024-09-09T08:12:48.529776",
          },
        ],
        ai_generated_graphs: [
          {
            title: {
              value: "Chart for Filippo Baroncini",
              is_ai_generated: false,
            },
            subtitle: null,
            plots: [
              {
                id: "plot_001",
                sequence_number: 1,
                name: "Athlete Weight Recorded Day Wise",
                metric: {
                  source_metric_name: "Athlete Weight Recorded Day Wise",
                  aggregation: "metric_as_is",
                },
                x_axis_meta_data: {
                  unit: "days",
                  is_log_scale: false,
                },
                y_axis_meta_data: {
                  unit: "kilograms",
                  is_log_scale: false,
                },
                plot_view_metadata: {
                  name: "Weight Plot",
                  type: "line",
                  color_name: "red",
                  is_show_on_legend: true,
                  stroke_width: null,
                  extra_fields: null,
                },
                created_at: "2024-09-09T08:12:48.529791",
                is_dummy_created_tag: true,
              },
              {
                id: "plot_002",
                sequence_number: 2,
                name: "Athlete Heart Rate Variability (HRV)",
                metric: {
                  source_metric_name: "Athlete Heart Rate Variability (HRV)",
                  aggregation: "metric_as_is",
                },
                x_axis_meta_data: {
                  unit: "days",
                  is_log_scale: false,
                },
                y_axis_meta_data: {
                  unit: "milliseconds",
                  is_log_scale: false,
                },
                plot_view_metadata: {
                  name: "HRV Plot",
                  type: "bar",
                  color_name: "green",
                  is_show_on_legend: true,
                  stroke_width: null,
                  extra_fields: null,
                },
                created_at: "2024-09-09T08:12:48.529798",
                is_dummy_created_tag: false,
              },
            ],
          },
        ],
      },
    }),
    []
  );

  useEffect(() => {
    (async () => {
      // const res = await getGraphId(2,"date","date")
      // console.log("res",res)
      // setGraphConfiguration(newApiRes)
      const aiGraphInScope = newApiRes.ai_graph_in_scope.ai_generated_graphs[0];
      const plots = aiGraphInScope.plots;

      const series = plots.map((plot) => {
        return {
          name: plot.plot_view_metadata.name,
          type: plot.plot_view_metadata.type,
          data: [],
          color: plot.plot_view_metadata.color_name,
          showSymbol: plot.plot_view_metadata.is_show_on_legend,
        };
      });

      const yAxisLabel = plots.map((plot) => {
        return {
          type: "value",
          name: plot.plot_view_metadata.name,
          axisLabel: {
            formatter: `{value} ${plot.y_axis_meta_data.unit}`,
            color: plot.plot_view_metadata.color_name,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: plot.plot_view_metadata.color_name,
            },
          },
          axisTick: {
            show: true,
          },
          splitLine: {
            show: false,
          },
          min: 0,
          max: 100,
        };
      });
      const xAxisLabel = plots[0].x_axis_meta_data.unit;
      setGraphData({
        aiGraphInScope,
        plots,
        series,
        yAxisLabel,
        xAxisLabel,
      });
    })();
  }, [newApiRes]);

  useEffect(() => {
    if (currentAthlete) {
      setFilteredAthlete([currentAthlete]);
    }
  }, [currentAthlete]);

  const handleFilterPopup = useCallback(() => {
    if (selectedIds && selectedIds.length > 0) {
      const filtered = athleteList.filter((filterAthlete) =>
        selectedIds.includes(filterAthlete.id)
      );
      setFilteredAthlete(filtered);
    } else {
      setFilteredAthlete(athleteList);
    }
    setTogglePopup(false);
  }, [selectedIds, athleteList]);

  const handleClearAll = () => {
    setFilteredAthlete([currentAthlete]);
    dispatch(setSelectedIds(null));
    setTogglePopup(false);
  };

  // history api call 
  useEffect(() => {
    (async () => {
      const res = await getAiGraphChatHistory()
      setGraphHistory(res.reverse())
      setHistoryLoading(false)
    })()
  }, [isHistoryApi])

  return (
    <div className={styles.container}>
      <div className={clsx(!isGraphExpanded && [styles.leftSection])}>
        {!isGraphExpanded && <ChatHistory graphHistory={graphHistory} setIsNewChat={setIsNewChat} isNewChat={isNewChat} loading={historyLoading} />}
      </div>
      {/* <div className={styles.rightSection}> */}
      <div
        className={clsx(
          !isGraphExpanded ? [styles.graphWrapper] : [styles?.graphExpand]
        )}
      >
        <div className={styles.topSection}>
          {!isGraphExpanded && (
            <div className={styles.userInfo} onClick={() => setTogglePopup(true)}>
              <div className={styles.profile}>
                <img
                  src={filteredAthlete?.[0]?.picture}
                  alt="Profile"
                  className={styles.athleteImage}
                />
                <span className={styles.name}>
                  {filteredAthlete?.[0]?.firstName}{" "}
                </span>
                <span className={styles.surname}>
                  {filteredAthlete?.[0]?.lastName}
                </span>
              </div>
              <div className={styles.profileInfo}>
                <img
                  src={flagUrl}
                  alt={filteredAthlete?.[0]?.countryCode}
                  className={styles.athleteFlag}
                />

                <DropDown className={styles.dropdown} />
              </div>
            </div>
          )}
          <GraphChart
            className={clsx(
              isGraphExpanded
                ? [styles.graphFullHeight, styles.leftSection]
                : styles?.graphContainer
            )}
            onExpand={handleExpandClick}
            isExpanded={isGraphExpanded}
            graphData={graphData}
            filteredAthlete={filteredAthlete}
            setFilteredAthlete={setFilteredAthlete}
            activateGraph={activateGraph}
          />

        </div>

        <div
          className={styles.chatWrapper}
          style={{ width: isGraphExpanded ? "30%" : "" }}
        >
          <ChatAiBody
            isExpanded={isGraphExpanded}
            isLoading={isLoading}
            onSendClick={handleSendClick}
            onChangeMultiline={handleInputChangeMultiline}
            messages={chatBotMessages}
            turnProcess={turnProcess}
          />

          <GraphAiChatInput
            isExpanded={isGraphExpanded}
            isLoading={isLoading}
            name="chatInput"
            onSend={handleSendClick}
            onChangeMultiline={handleInputChangeMultiline}
          />
        </div>
      </div>
      {/* </div> */}
      {togglePopup && (
        <AthletePopup
          handleClearAll={handleClearAll}
          handleSubmit={handleFilterPopup}
          athleteList={athleteList}
          onClose={() => {
            setTogglePopup(false);
          }}
          multiSelect={false}
        />
      )}
    </div>
  );
};

export default GraphAiBody;
