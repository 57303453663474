import { Menubar } from "components/menubar/menubar";
import React, { useEffect, useState } from "react";
import styles from "./graph-ai.module.scss";
import { GraphAiHeader } from "components/graph-ai/graph-ai-header";
import GraphAiBody from "../../components/graph-ai/graph-ai-body";

const GraphAi = () => {
  return (
    <div className={styles.wrapper}>
      <Menubar />
      <div className={styles.page}>
        <GraphAiHeader />
        <GraphAiBody />
      </div>
    </div>
  );
};

export default GraphAi;
