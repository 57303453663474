import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { getAiGraphId } from "api/ai-graph";
import { RootState } from "store/index";


const initialState={
    aiGraphId:""
}

export const aiGraphSlice = createSlice({
    name: "aiGraph",
    initialState,
    reducers: {
        setAiGraphId: (state, action: PayloadAction<any>) => {
        state.aiGraphId = action.payload;
      },
    
    },
  });
  
  export const {
    setAiGraphId
  } = aiGraphSlice.actions;


  export const fetchAiGraphId = (startDate: string,endDate:string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
      const athleteId = getState().shared?.selectedAthleteId;
      console.log("innn",athleteId)
      if (athleteId) {
        const response = await getAiGraphId(athleteId,startDate,endDate);
        if (response) {
          dispatch(setAiGraphId(response));
        }
      } else return;
    };
  };

  export const aiGraph = aiGraphSlice.reducer;
