import React, { useCallback, useEffect, useState } from "react";
import styles from "./graph-chart.module.scss";
import { GraphAiHeader } from "components/graph-ai/graph-ai-header";
import ChatHistory from "./chat-history";
import { ReactComponent as EditIcon } from "images/editPencil.svg";
import { ReactComponent as BookMarkIcon } from "images/bookMark.svg";
import { ReactComponent as ExpandIcon } from "images/expands-right.svg";
import { ReactComponent as UploadIcon } from "images/upload.svg";
import { ReactComponent as PresentationLine } from "images/presentation-chart-line.svg";
import { ReactComponent as Graph } from "images/graph.svg";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { getAthleteList, getCurrentAthlete, getSeletedIds, setSelectedIds } from "store/slices/shared";
import { countryCodesMapping } from "country-codes-mapping";
import { ReactComponent as DropDown } from "images/dropdown.svg";
import { DEFAULT_CDN_SUFFIX, DEFAULT_CDN_URL } from "const";
import ReactECharts from "echarts-for-react";
import { AthletePopup } from "components/athlete-popup/athlete-popup";

type Props = {
  className: string;
  onExpand: () => void;
  isExpanded: boolean;
  graphData: any;
  filteredAthlete: any;
  setFilteredAthlete: (value: any) => void
  activateGraph: boolean;
}

const GraphChart = ({ className, onExpand, isExpanded, graphData, filteredAthlete, setFilteredAthlete, activateGraph }: Props) => {
  const dispatch = useDispatch();
  const athleteList = useSelector(getAthleteList);
  const selectedIds = useSelector(getSeletedIds);
  const [graphConfig, setGraphConfig] = useState({});
  const [togglePopup, setTogglePopup] = useState(false);
  const [isHovered, setIsHovered] = useState(false)
  const athlete = useSelector(getCurrentAthlete);

  const countryAlpha2Code =
    athlete?.countryCode && countryCodesMapping[athlete.countryCode]
      ? countryCodesMapping[athlete?.countryCode]
      : "AE";
  const flagUrl = `${DEFAULT_CDN_URL}${countryAlpha2Code.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;

  // useEffect(() => {
  //   if (!graphData) return;
  //   if (graphData?.yAxisLabel) {
  //     console.log("first", graphData);
  //     setGraphConfig({
  //       title: {
  //         text: graphData?.aiGraphInScope?.title?.value,
  //         left: "left",
  //         textStyle: {
  //           color: '#F3F3F6',
  //           fontSize:12,
  //                     fontFamily:'Montserrat',
  //                     fontWeight:'normal'
  //         },
  //       },
  //       tooltip: {
  //         trigger: "axis",
  //       },
  //       xAxis: {
  //         type: "category",
  //         // name: graphData?.xAxisLabel,
  //         axisLabel: {
  //           // formatter: `{value} ${graphData?.xAxisLabel}`,
  //           color:  '#F3F3F6',
  //         },
  //         axisLine: {
  //           show: true,
  //           lineStyle: {
  //             color: "#fff",
  //           },
  //         },
  //         axisTick: {
  //                     show: false,
  //                   },

  //         data: graphData?.xAxisLabel,
  //       },
  //       // yAxis: {
  //       //   type: 'value'
  //       // },
  //       yAxis: graphData?.yAxisLabel,
  //       series: graphData?.series,
  //       // backgroundColor: "#2c343c",
  //     });
  //   }

  // }, [graphData]);

  useEffect(() => {
    setGraphConfig(
      {
        title: {
          text: 'Graph name',
          left: 'left',
          textStyle: {
            color: '#F3F3F6',
            fontSize: 12,
            fontFamily: 'Montserrat',
            fontWeight: 'normal'
          },
        },
        xAxis: {
          type: 'category',
          data: Array(10).fill('00.00'),
          axisLabel: {
            color: '#ccc',
          },
          axisLine: {
            lineStyle: {
              color: '#ccc',
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 5,
          interval: 1,
          axisLabel: {
            formatter: '00',
            color: '#ccc',
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: '#ccc',
            },
          },
          splitLine: {
            show: false,
          },

        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true,
          },
        ],

      }
    );

  }, []);



  const handleFilterPopup = useCallback(() => {
    if (selectedIds && selectedIds.length > 0) {
      const filtered = athleteList.filter((filterAthlete) =>
        selectedIds.includes(filterAthlete.id)
      );
      setFilteredAthlete(filtered);
    } else {
      setFilteredAthlete(athleteList);
    }
    setTogglePopup(false);
  }, [selectedIds, athleteList, setFilteredAthlete]);



  const handleClearAll = () => {
    setFilteredAthlete([athlete])
    dispatch(setSelectedIds(null));
    setTogglePopup(false);
  };



  return (
    <div className={clsx(styles.graphContainer, className)}>
      {/* Athele Profile Dropdown */}
      {isExpanded && (
        <div className={styles.profile} onClick={() => setTogglePopup(true)}>
          <img
            src={filteredAthlete?.[0]?.picture}
            alt="Profile"
            className={styles.athleteImage}
          />

          <span className={styles.name}>{filteredAthlete?.[0]?.firstName} </span>
          <span className={styles.surname}>{filteredAthlete?.[0]?.lastName}</span>
          <div className={styles.athleteFlag}>
            <img src={flagUrl} alt={filteredAthlete?.[0]?.countryCode} />
          </div>
          <DropDown className={styles.dropdown} />
        </div>
      )}
      <div className={clsx(isExpanded ? [styles.graphExpandContainer] : styles.graphHeaderWrapper)} onMouseLeave={() => setIsHovered(false)}>
        <div className={styles.graphHeaders} onMouseEnter={() => setIsHovered(true)}>
          {activateGraph ? <>
            <ReactECharts
              option={graphConfig}
              style={{ height: "30vh", width: "100%" }}
            />
            {
              isHovered &&
              <div
                className={clsx(
                  styles.graphIcons,
                  !isExpanded && styles.graphWidth
                )}
              >
                <EditIcon className={clsx(styles.icons, styles.editIcon)} />
                <BookMarkIcon className={styles.icons} style={{ padding: "2px" }} />
                <UploadIcon className={styles.icons} />
                <ExpandIcon
                  className={clsx(styles.icons, styles.expandIcon)}
                  onClick={onExpand}
                />
              </div>
            }
          </> : <div className={styles.emptyGraphWrapper}> <PresentationLine className={styles.graphImage} /> <span>Your AI-generated graph will render here!</span> </div>}
        </div>
      </div>
      {togglePopup && (
        <AthletePopup
          handleClearAll={handleClearAll}
          handleSubmit={handleFilterPopup}
          athleteList={athleteList}
          onClose={() => {
            setTogglePopup(false);
          }}
          multiSelect={false}
        />
      )}
    </div>
  );
};

export default GraphChart;
