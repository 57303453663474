import React, { FC, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from "./chat-body.module.scss";
import { ChatBotMessage } from "components/chat-bot/chat-bot";
import { ChatBubble } from "../chat-bubble/chat-bubble";
import { ReactComponent as ChatLogo } from "images/chat-logo.svg";
import { ReactComponent as Like } from "images/thumbs_up.svg";
import { ReactComponent as DisLike } from "images/thumbs_down.svg";
import { CHAT_LOADER_COLOR } from "components/charts/colors";
import { FeedbackPopup } from "components/feedback-popup/feedback-popup";
import { getUserChatFeedback } from "api/messages";
import { useSelector } from "react-redux";
import { getCurrentAthlete } from "store/slices/shared";

type Props = {
  messages: ChatBotMessage[];
  isLoading?: boolean;
  isExpanded?: boolean;
  threadId?: string | null;
  messageId?: string;
  isLatestMsg?: string;
  onLikeClick: (id: string | undefined) => void;
  onDislikeClick: (id: string | undefined) => void;
};

const feedbackOptions = [
  "Irrelevant response",
  "Inaccurate information or data",
  "Calculation error",
  "Other ...",
];

export const ChatBody: FC<Props> = ({
  messages,
  isLoading,
  isExpanded,
  threadId,
  messageId,
  isLatestMsg,
  onLikeClick,
  onDislikeClick,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFeedbackPopup, setIsFeedbackPopup] = useState(false);
  const [isLikeClicked, setIsLikeClicked] = useState(false);
  const currentAthlete = useSelector(getCurrentAthlete);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [tag, setTag] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [isSubmitClick, setIsSubmitClick] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isHovered, setIsHovered] = useState<string | undefined>();
  const [isPrevMsgClicked, setIsPrevMsgClicked] = useState(false);
  const [selectedMsgId, setSelectedMsgId] = useState<string | undefined>("");
  const [prevMessagesLength, setPrevMessagesLength] = useState(messages.length);
  const [prevLastMessage, setPrevLastMessage] = useState(
    messages[messages.length - 1]?.message || ""
  );

  const handleLikeClick = (msgId: string | undefined) => {
    setSelectedMsgId(msgId);
    onLikeClick(msgId);
    setSelectedIcon("thumbs_up");
    setIsLikeClicked(!isLikeClicked);
    setIsSubmitClick(true);
  };

  // for prev msg dislike click
  const handleDisLikeClick = (msgId: string | undefined) => {
    setSelectedMsgId(msgId);
    setIsPrevMsgClicked(true);
    setSelectedIcon("thumbs_down");
    setIsSubmitClick(true);
    onDislikeClick(msgId);
  };

  const handleLastMsgDislikeClick = (msgId: string | undefined) => {
    onDislikeClick(msgId);
    setSelectedMsgId(msgId);
    setSelectedIcon("thumbs_down");
  };

  // Scroll only if the message length increases or the last message changes
  useEffect(() => {
    const currentMessagesLength = messages.length;
    const currentLastMessage = messages[messages.length - 1]?.message || "";
    if (
      currentMessagesLength > prevMessagesLength ||
      (prevLastMessage !== currentLastMessage &&
        currentLastMessage.trim() !== "")
    ) {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }
    setPrevMessagesLength(currentMessagesLength);
    setPrevLastMessage(currentLastMessage);
  }, [messages, isLatestMsg, prevLastMessage, prevMessagesLength]);

  // useEffect(() => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollTop = containerRef.current.scrollHeight;
  //   }
  // }, [isLatestMsg,messages]);

  // to add space when feedback option added
  useEffect(() => {
    if (
      selectedIcon === "thumbs_down" &&
      containerRef.current &&
      !isPrevMsgClicked
    ) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [selectedIcon, isPrevMsgClicked]);

  const handleFeedbackOption = (option: string) => {
    if (option === "Other ...") {
      setIsFeedbackPopup(true);
    } else {
      setTag(option);
      setIsSubmitClick(true);
    }
  };

  useEffect(() => {
    (async () => {
      if (threadId && isSubmitClick) {
        const res = await getUserChatFeedback(
          currentAthlete?.id,
          threadId,
          selectedMsgId,
          selectedIcon,
          tag,
          userMessage
        );
        setIsSubmitClick(false);
        if (selectedIcon === "thumbs_down" && isPrevMsgClicked) {
          setIsSuccess(false);
          setSelectedIcon("");
          setIsPrevMsgClicked(false);
          setIsFeedbackPopup(false);
        }
        if (selectedIcon === "thumbs_down" && !isPrevMsgClicked) {
          setIsSuccess(true);
        }
        setTag("");
        setUserMessage("");
      }
    })();
  }, [
    isSubmitClick,
    currentAthlete,
    threadId,
    selectedIcon,
    tag,
    userMessage,
    isHovered,
    selectedMsgId,
    isPrevMsgClicked,
  ]);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setIsSuccess(false);
        setSelectedIcon("");
        setIsPrevMsgClicked(false);
        setIsFeedbackPopup(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);

  //if new msg is asked need to remove the feedback option
  useEffect(() => {
    if (isLoading) {
      setSelectedIcon("");
    }
  }, [isLoading]);

  const handleMouseLeave = () => {
    setIsHovered(undefined);
  };

  return (
    <div
      className={clsx(styles.chatBody, isExpanded && styles.chatBodyMaximize)}
      ref={containerRef}
    >
      {messages.map((message, index) => (
        <div
          className={clsx(
            styles.chatBubble,
            message.isUser && styles.chatBubbleUser
          )}
          key={index}
        >
          {!message.isUser && <ChatLogo className={styles.systemImage} />}

          {isLoading && index === messages.length - 1 ? (
            <div
              className={styles.loader}
              style={
                { "--loader-color": CHAT_LOADER_COLOR } as React.CSSProperties
              }
            ></div>
          ) : (
            <>
              <div onMouseLeave={handleMouseLeave}>
                <ChatBubble
                  message={message.message}
                  isUser={message.isUser}
                  isExpand={isExpanded}
                  setIsHovered={setIsHovered}
                  id={message.id}
                />

                {/* for the last chat  */}
                {!message.isUser && isLatestMsg === message?.id && (
                  <div className={styles.thumbicons}>
                    <Like
                      className={`${styles.like} ${message.isLiked && styles.liked
                        }`}
                      onClick={!message.isLiked && !message.isDisliked ? () => handleLikeClick(message?.id) : undefined}
                    // onClick={() => handleLikeClick(message?.id)}
                    />

                    <DisLike
                      className={`${styles.like} ${message.isDisliked && styles.disliked
                        }`}
                      onClick={!message.isLiked && !message.isDisliked ? () => handleLastMsgDislikeClick(message?.id) : undefined}
                    // onClick={() => handleLastMsgDislikeClick(message?.id)}
                    />
                  </div>
                )}
                {/* for prev chat */}
                {isHovered === message?.id &&
                  !message.isUser &&
                  !message?.isDefaultMsg &&
                  !(isLatestMsg === message?.id) && (
                    <div className={styles.thumbicons}>
                      <Like
                        className={`${styles.like} ${message.isLiked && styles.liked
                          }`}
                        onClick={!message.isLiked && !message.isDisliked ? () => handleLikeClick(message?.id) : undefined}
                      // onClick={() => handleLikeClick(message?.id)}
                      />
                      <DisLike
                        className={`${styles.like} ${message.isDisliked && styles.disliked
                          }`}
                        onClick={!message.isLiked && !message.isDisliked ? () => handleDisLikeClick(message?.id) : undefined}
                      // onClick={() => handleDisLikeClick(message?.id)}
                      />
                    </div>
                  )}
              </div>
            </>
          )}
        </div>
      ))}

      {selectedIcon === "thumbs_down" && (
        <div className={styles.feedbackWrapper}>
          {!isSuccess &&
            !isPrevMsgClicked &&
            feedbackOptions?.map((feed) => {
              return (
                <div
                  className={`${styles.feedbackOption} ${tag == feed && styles.selectedOption
                    }`}
                  onClick={() => handleFeedbackOption(feed)}
                >
                  {feed}
                </div>
              );
            })}
        </div>
      )}

      {selectedIcon === "thumbs_down" && isSuccess && !isPrevMsgClicked && (
        <div className={styles.feedbackSuccess}>Thanks for your feedback</div>
      )}
      {isFeedbackPopup && (
        <FeedbackPopup
          onClose={() => setIsFeedbackPopup(false)}
          setTag={setTag}
          tag={tag}
          setUserMessage={setUserMessage}
          setIsSubmitClick={setIsSubmitClick}
          isSuccess={isSuccess}
          title={"Provide feedback"}
          displayOption={true}
          userMessage={userMessage}
        />
      )}
    </div>
  );
};
