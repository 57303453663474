import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import clsx from "clsx";
import { ReactComponent as Close } from "images/close.svg";
import { PrimaryButton } from "components/primary-button/primary-button";

import styles from "./athlete-popup.module.scss";
import { AthleteSearch } from "pages/athlete-selection/athlete-search/athlete-search";
import { Athlete } from "types";
import { ReactComponent as Checkbox } from "images/checkbox.svg";
import { ReactComponent as SelectedCheckbox } from "images/checkbox_selected.svg";
import { useDispatch, useSelector } from "hooks/app-hooks";
import { getSeletedIds, setSelectedIds } from "store/slices/shared";

type Props = {
  onClose: () => void;
  athleteList: Athlete[];
  handleSubmit: () => void;
  handleClearAll: () => void;
  multiSelect?:boolean
};

export const AthletePopup: FC<Props> = ({
  onClose,
  athleteList,
  handleSubmit,
  handleClearAll,
  multiSelect=true
}) => {
  const dispatch = useDispatch();
  const selectedIds = useSelector(getSeletedIds);
  const [input, setInput] = useState<string>("");
  const [filteredAthletes, setFilteredAthletes] = useState(athleteList);

  useEffect(() => {
    const regex = new RegExp(input, "i");
    // const filtered = athleteList?.filter(
    //   (athlete) =>
    //     regex.test(athlete?.firstName) ||
    //     regex.test(athlete?.lastName) ||
    //     regex.test(athlete?.countryCode)
    // );
    // setFilteredAthletes(filtered);
    const uniqueIds = new Set();

    const filtered = athleteList?.filter((athlete) => {
      const matches =
        regex.test(athlete?.firstName) ||
        regex.test(athlete?.lastName) ||
        regex.test(athlete?.countryCode);
      if (matches && !uniqueIds.has(athlete?.id)) {
        uniqueIds.add(athlete?.id);
        return true;
      }
      return false;
    });
    setFilteredAthletes(filtered);
  }, [input, athleteList]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const manageList = useMemo(() => {
    
    return (id: number) => {
      if (selectedIds && multiSelect) {
        if (selectedIds.includes(id)) {
          const arr = selectedIds.filter((item) => item !== id);
          return dispatch(setSelectedIds(arr));
        }
        return dispatch(setSelectedIds([...selectedIds, id]));
      }
      return dispatch(setSelectedIds([id]));
    };
  }, [selectedIds, dispatch,multiSelect]);

  return (
    <div className={clsx(styles.popupBox, "comparison-dropdown")}>
      <div
        className={clsx(styles.inputBox, styles.box)}
        data-testid="add-comparison-list"
      >
        <div className={styles.header}>
          <h3 className={styles.title}>Filter</h3>
          <Close
            className={styles.closeButton}
            onClick={onClose}
            aria-label="Close"
            title="Close"
          />
        </div>
        <div className={styles.athleteCardWrapper}>
          <div className={styles.athleteSearch}>
            <span>Athletes</span>
            <AthleteSearch
              input={input}
              handleChange={handleSearchChange}
              className={styles.searchBar}
            />
          </div>
          <div className={styles.listWrapper}>
            <div className={styles.list}>
              {filteredAthletes?.length > 0 ? (
                filteredAthletes?.map((item: Athlete, i: number) => {
                  return (
                    <div
                      className={clsx(
                        styles.item,
                        !(selectedIds && selectedIds.includes(item?.id)) &&
                          styles.itemDisabled
                      )}
                      key={i.toString()}
                      onClick={() => manageList(item?.id)}
                    >
                      <button
                        type="button"
                        className={styles.checkbox}
                        onClick={() => manageList(item?.id)}
                      >
                        {selectedIds && selectedIds.includes(item?.id) ? (
                          <SelectedCheckbox />
                        ) : (
                          <Checkbox />
                        )}
                      </button>
                      <img
                        className={styles.itemImage}
                        src={item?.picture}
                        alt="Profile"
                      />
                      <div className={styles.itemContent}>
                        <span className={styles.itemName}>
                          {item?.firstName} {item?.lastName}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={styles.noData}>No Athletes Found</div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <PrimaryButton
            className={styles.clearSelection}
            onClick={handleClearAll}
            title={"Clear All"}
          />
          <PrimaryButton
            className={styles.addButton}
            onClick={handleSubmit}
            title={"Show Results"}
          />
        </div>
      </div>
    </div>
  );
};
