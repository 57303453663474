import { trackError } from "appInsights/logInsights";
import { axiosInstance } from "axios-instance/axios-instance";

// get all medical reports with pagination
export const getAiGraphId = async (
  athleteId: number,
  dateStart: string,
  dateEnd: string
) => {
  try {
    const data = {
      data_sources: [
        {
          type: "AthleteDataSourceMetadata",
          athlete_id: `"${athleteId}"`,
        },
        {
          type: "GraphDateSourceMetadata",
          date_name: "graph_start_date",
          selected_date: "2024-06-11",
        },
        {
          type: "GraphDateSourceMetadata",
          date_name: "graph_end_date",
          selected_date: "2024-09-11",
        },
      ],
    };
    const resp = await axiosInstance.post(`ai/v1/ai-graph/new`,data);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getAiGraphId" });
    console.error(
      "Request failed:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const getAiGraphChatQuery = async (graphId: number, query: string,onChunkReceive: (chunk: string) => void) => {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `ai/v1/ai-graph/${graphId}/ask?stream=true`,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      data:{
        "query": query
      },
      responseType: 'stream', 
      onDownloadProgress: (progressEvent) => {
        const reader = progressEvent.currentTarget.responseText; 
        // console.log("reader",reader,typeof reader,JSON.stringify(reader))
        // const chunks = reader.split('\n').filter((chunk:any)=> chunk.startsWith('data:')).map((chunk:any) => chunk.replace('data: ', ''));

        // Process each chunk and pass it to the handler
        // chunks.forEach((chunk:any) => {
        //   onChunkReceive(chunk);
        // });
        onChunkReceive(reader)
        // console.log("reader",reader?.data)
      }
    });
    // console.log("Streaming completed", response);
    return response
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const getAiGraphConfiguration = async (graphId:number) => {
  try {
    const resp = await axiosInstance.get(`ai/v1/ai-graph/${graphId}/configuration`);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getAiGraphConfiguration" });
    // console.error(
    //   "Request failed:",
    //   error.response ? error.response.data : error
    // );
    throw error;
  }
};





export const getAiGraphChatHistory = async () => {
  try {
    const resp = await axiosInstance.get(`ai/v1/ai-graph/user_history_panel`);
    return resp.data;
  } catch (error: any) {
    trackError(error as Error, { location: "getAiGraphChatHistory" });
    console.error(
      "Request failed:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};
