import { FC } from "react";
import styles from "./graph-ai-header.module.scss";
import { CoachInfo } from "components/coach-info/coach-info";

export const GraphAiHeader: FC = () => {
  return (
    <div className={styles.teamSelectionHeader}>
      <div className={styles.teamLeft}>
        <span className={styles.teamText}>Graph Ai</span>
      </div>
      <CoachInfo className={styles.coachButton} />
    </div>
  );
};
