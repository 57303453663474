import { Menubar } from "components/menubar/menubar";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./chat-history.module.scss";
import { ReactComponent as PresentationLine } from "images/presentation-chart-line.svg";
import { ReactComponent as Document } from "images/document.svg";
import { ReactComponent as HorizontalDots } from "images/horizontal-dots.svg";
import clsx from "clsx";
import { ReactComponent as EditPencilBoxIcon } from "images/EditPencilBoxIcon.svg";

type Props = {
  graphHistory: [],
  setIsNewChat: (value: boolean) => void;
  isNewChat: boolean;
  loading: boolean
}

const ChatHistory = ({ graphHistory, setIsNewChat, isNewChat, loading }: Props) => {

  const texts = [
    "Loreum Ipsum is simply",
    "Loreum Ipsum is simply",
    "Loreum Ipsum is simply",
  ];

  const handleClick = () => {
    setIsNewChat(!isNewChat)
  };

  return (
    <>
      <div className={styles.history}>
        {/* <div className={styles.chatFolder}>
          <Document />
          <span className={styles.folderName}>My Graphs</span>
        </div>
        <ul className={styles.folderTextBorder}>
          {texts?.map((text) => {
            return (
              <div className={styles.folderText}>
                <PresentationLine />{" "}
                <li className={styles.historyText}>{text}</li>
              </div>
            );
          })}
        </ul> */}
        <div className={styles.chatHistoryWrapper}>
          <ul
            className={clsx(styles.chatHistoryContainer, styles.folderTextBorder)}
          >
            {
              loading &&
              <div className={styles.chatHistory}>
                <li className={styles.chatHistoryText}>
                  New chat
                </li>
              </div>
            }
            {graphHistory?.map((history: any) => {
              return (
                <div className={styles.chatHistory}>
                  <li className={styles.chatHistoryText}>
                    {history?.ai_graph_meta_data?.title?.value}
                  </li>
                  <HorizontalDots />
                </div>
              );
            })}
          </ul>
        </div>
        {/* <div className={styles.seperator}></div> */}
        <div className={styles.newSessionBtnWrapper}>
          <button type="button" className={styles.button} onClick={handleClick}>
            New Session <EditPencilBoxIcon className={styles.expandDown} />
          </button>
        </div>
      </div>
    </>
  );
};

export default ChatHistory;
