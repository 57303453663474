import React, { useEffect, ChangeEvent, KeyboardEvent, useState } from "react";
import styles from "./graph-ai-chat-input.module.scss";
import { PrimaryButton } from "components/primary-button/primary-button";
import { ReactComponent as SendIcon } from "images/send.svg";
import clsx from "clsx";

type AIGraphinputProps = {
  name: string;
  isLoading: boolean;
  isExpanded:boolean;
  onChangeMultiline?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onSend: () => void;
};

const GraphAiChatInput = ({
  isExpanded,
  name = "aiChatInput",
  onChangeMultiline,
  isLoading,
  onSend,

}: AIGraphinputProps) => {
  const [value, setValue] = useState<string>("");
  const handleChangeMultiline = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onChangeMultiline) {
      onChangeMultiline(e);
    }
  };

  const handleMessageSend = () => {
    onSend();
    setValue("");
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleMessageSend();
    }
  };


  return (
    <>
      <div
        className={clsx(isExpanded ? styles.inputExpandWrapper : styles.inputWrapper)}
      >
        <textarea
          id={name}
          name={name}
          value={value}
          // ref={textareaRef}
          placeholder="How can I help you?"
          onChange={handleChangeMultiline}
          onKeyDown={handleKeyDown}
          className={clsx(styles.textarea)}
        />
        <PrimaryButton
          onClick={handleMessageSend}
          // disabled={isLoading}
          className={styles.sendWrapper}
        >
          <SendIcon />
        </PrimaryButton>
      </div>
    </>
  );
};

export default GraphAiChatInput;